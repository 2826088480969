import React from 'react'

const PhoneNumber = () => {
	return (
		<div className="container container--standard spacing-bottom phone-link-container">
			<a className="phone-link" href="tel:352.377.9770">
				352.377.9770
			</a>
		</div>
	)
}

export default PhoneNumber
