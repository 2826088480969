import React, { useState } from 'react'
import { Link } from 'gatsby'
import Tooltip from '../Tooltip'
import InputButton from './InputButton'
import LinkButton from './LinkButton'
import AnchorButton from './AnchorButton'
import ButtonButton from './ButtonButton'

// ----------------------------------------
// - Variants
// ----------------------------------------
// * primary
// * secondary
// * success
// * warning
// * danger
// * info
// * light
// * dark
// * link

const Button = ({
	isInput,
	label,
	variant,
	outline,
	size,
	block,
	className,
	type,
	onClick,
	isLink,
	to,
	iconOnly,
	icon,
	iconType,
	iconSize,
	isAnchor,
	cursor,
	disabled,
	tooltip,
	ttText,
	ttPos,
	noBdr,
	iconType2,
	icon2,
	iconSize2,
	style,
	inline,
	useMotion,
	noMotion,
	motionVariant,
	whileHover,
	whileTap,
	initial,
	animate,
	transition,
}) => {
	const [hovering, setHovering] = useState(false)

	const handleMouseEvent = () => {
		setHovering(!hovering)
	}

	// if (style) {
	// console.log('style = ', style)
	// }

	if (isInput) {
		return (
			<InputButton
				label={label}
				variant={variant}
				outline={outline}
				size={size}
				block={block}
				className={className}
				type={type}
				onClick={onClick}
				cursor={cursor}
				disabled={disabled}
				noBdr={noBdr}
				style={style}
				useMotion={useMotion}
				noMotion={noMotion}
				motionVariant={motionVariant}
				whileHover={whileHover}
				whileTap={whileTap}
				initial={initial}
				animate={animate}
				transition={transition}
			/>
		)
	} else if (isLink)
		return (
			<LinkButton
				Link={Link}
				to={to}
				variant={variant}
				outline={outline}
				size={size}
				block={block}
				className={className}
				icon={icon}
				iconOnly={iconOnly}
				disabled={disabled}
				noBdr={noBdr}
				inline={inline}
				type={type}
				onClick={onClick}
				cursor={cursor}
				style={style}
				icon2={icon2}
				iconType={iconType}
				iconSize={iconSize}
				iconType2={iconType2}
				iconSize2={iconSize2}
				label={label}
				useMotion={useMotion}
				noMotion={noMotion}
				motionVariant={motionVariant}
				whileHover={whileHover}
				whileTap={whileTap}
				initial={initial}
				animate={animate}
				transition={transition}
			/>
		)
	else if (isAnchor)
		return (
			<AnchorButton
				to={to}
				variant={variant}
				outline={outline}
				size={size}
				block={block}
				className={className}
				icon={icon}
				iconOnly={iconOnly}
				disabled={disabled}
				noBdr={noBdr}
				inline={inline}
				type={type}
				onClick={onClick}
				cursor={cursor}
				style={style}
				icon2={icon2}
				iconType={iconType}
				iconSize={iconSize}
				iconType2={iconType2}
				iconSize2={iconSize2}
				label={label}
				useMotion={useMotion}
				noMotion={noMotion}
				motionVariant={motionVariant}
				whileHover={whileHover}
				whileTap={whileTap}
				initial={initial}
				animate={animate}
				transition={transition}
			/>
		)
	else {
		return (
			<ButtonButton
				variant={variant}
				outline={outline}
				size={size}
				block={block}
				className={className}
				icon={icon}
				iconOnly={iconOnly}
				disabled={disabled}
				noBdr={noBdr}
				ttPos={ttPos}
				inline={inline}
				onClick={onClick}
				cursor={cursor}
				style={style}
				tooltip={tooltip}
				handleMouseEvent={handleMouseEvent}
				icon2={icon2}
				iconType={iconType}
				iconSize={iconSize}
				iconType2={iconType2}
				iconSize2={iconSize2}
				label={label}
				Tooltip={Tooltip}
				hovering={hovering}
				useMotion={useMotion}
				noMotion={noMotion}
				motionVariant={motionVariant}
				whileHover={whileHover}
				whileTap={whileTap}
				initial={initial}
				animate={animate}
				transition={transition}
			/>
		)
	}
}

export default Button
