import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion, AnimatePresence } from 'framer-motion'

const Tooltip = ({ mouseEnter, mouseLeave, children, hovering, tooltip, variant }) => {
	const tooltipVariant = {
		in: { x: 20, opacity: 1 },
		out: { x: 300, opacity: 0 },
	}

	useEffect(() => {
		// console.log('tooltip variant = ', variant)
	}, [variant])

	const elemToAppendTo = document.getElementById('root')

	const tooltipItem = (
		<AnimatePresence>
			{hovering && (
				<motion.div
					className={`tooltip${variant ? ' tooltip--' + variant : ''}`}
					// ref={tooltipRef}
					variants={tooltipVariant}
					initial="out"
					animate="in"
					exit="out"
				>
					<div className="tooltip__content-container">
						<div className="tooltip__icon-container">
							<FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'info-circle' }} />
						</div>
						<div className="tooltip__text">{tooltip}</div>
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	)
	return ReactDOM.createPortal(tooltipItem, elemToAppendTo)
}

export default Tooltip
