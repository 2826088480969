import React from 'react'
import Icons from './Icons'
import { motion } from 'framer-motion'

const buttonDefault = {
	initial: {
		y: -3,
	},
	animate: {
		y: 0,
	},
	whileHover: {
		y: -3,
	},
}

const ButtonButton = ({
	variant,
	outline,
	size,
	block,
	className,
	icon,
	iconOnly,
	disabled,
	noBdr,
	ttPos,
	inline,
	onClick,
	cursor,
	style,
	tooltip,
	handleMouseEvent,
	icon2,
	iconType,
	iconSize,
	iconType2,
	iconSize2,
	label,
	Tooltip,
	hovering,
	useMotion,
	noMotion,
	motionVariant,
	whileHover,
	whileTap,
	initial,
	animate,
	transition,
}) => {
	const classes = `btn${variant ? ' btn-' + variant : ''}${outline ? '-outline' : ''}${
		size ? ' btn-' + size + ' ' : ''
	}${block ? ' block ' : ''}${className ? ' ' + className + ' ' : ''}${icon ? ' has-icon' : ''}${
		iconOnly ? ' icon-only' : ''
	}${disabled ? ' disabled ' : ''}${noBdr ? ' no-border' : ''}${
		ttPos ? ' tt-' + ttPos : ' tt-top'
	}${inline ? ' inline' : ''}`

	const btnStyle =
		cursor && style
			? { cursor: cursor, ...style }
			: !cursor && style
			? style
			: cursor && !style
			? { cursor: cursor }
			: null

	return (
		<motion.button
			// prettier-ignore
			variants={noMotion ? null : motionVariant ? motionVariant : buttonDefault}
			// whileHover={whileHover}
			whileHover={noMotion ? null : whileHover ? whileHover : 'whileHover'}
			whileTap={noMotion ? null : whileTap}
			// animate={animate}
			animate={noMotion ? null : animate ? animate : 'animate'}
			// initial={initial}
			initial={noMotion ? null : initial ? initial : 'initial'}
			transition={noMotion ? null : transition ? transition : 'transition'}
			className={classes}
			disabled={disabled ? true : false}
			onClick={onClick}
			style={btnStyle}
			onMouseEnter={tooltip ? () => handleMouseEvent() : null}
			onMouseLeave={tooltip ? () => handleMouseEvent() : null}
		>
			<Icons
				icon2={icon2}
				iconType={iconType}
				icon={icon}
				iconSize={iconSize}
				iconType2={iconType2}
				iconSize2={iconSize2}
				iconOnly={iconOnly}
				label={label}
			/>
			{tooltip && <Tooltip hovering={hovering} tooltip={tooltip} variant={variant} />}
		</motion.button>
	)
}

export default ButtonButton
