import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PhoneNumber from '../components/PhoneNumber'
import Address from '../components/Address'

const LayoutMain = ({ children }) => {
	return (
		<div className="layout-main">
			<Header />
			<div className="page-content-container">
				<PhoneNumber />
				<Address mapIcon />
			</div>
			<div className="page-content-container">{children}</div>
			<Footer />
		</div>
	)
}

export default LayoutMain
