import React from 'react'
import GoogleMapReact from 'google-map-react'
import { useStaticQuery, graphql } from 'gatsby'
import MapPin from './MapPin'

const Map = () => {
	const data = useStaticQuery(getLocation)
	console.log('data = ', data)
	const { location } = data
	const { address } = location
	return (
		<div className="map-container">
			<GoogleMapReact
				bootstrapURLKeys={{
					key: `AIzaSyCgFFc5mBB4bSAL4PwsIZfqc_G9zwxjuD0`,
					language: 'en',
				}}
				defaultCenter={{ lat: address.lat, lng: address.lon }}
				center={{ lat: address.lat, lng: address.lon }}
				defaultZoom={15}
			>
				<MapPin lat={address.lat} lng={address.lon} />
			</GoogleMapReact>
		</div>
	)
}

export const getLocation = graphql`
	query GetLocation {
		location: contentfulLocation {
			id
			locationTitle
			address {
				lat
				lon
			}
		}
	}
`

export default Map
