import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import Button from './button/Button'

const ModalContent = ({ children, hide }) => {
	return (
		<motion.div
			className="modal__container"
			initial={{ opacity: 0, y: -200, x: '-50%' }}
			animate={{ opacity: 1, y: 0, x: '-50%' }}
			exit={{ opacity: 0, y: -200, x: '-50%', transition: { y: { delay: 0.1 } } }}
		>
			<div className="modal__wrapper">
				<div className="modal__content">{children}</div>
				<Button
					className="close-btn"
					iconOnly
					icon="times"
					iconType="fas"
					// tooltip
					ttText="Close"
					ttPos="top"
					onClick={() => hide(false)}
				/>
			</div>
		</motion.div>
	)
}

export default ModalContent
