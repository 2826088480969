import React from 'react'
import FooterImg from '../img/surfer.jpg'
import Logo from '../svg/Logo'
import { format } from 'date-fns'
import Address from './Address'

const Footer = () => {
	const year = format(new Date(), 'yyyy')

	return (
		<footer className="footer">
			<div className="footer-image">
				<img
					className="footer-main__bg-image img-fluid"
					src={FooterImg}
					alt="Surfer Riding a Wave"
				/>
				<div className="footer-main__logo logo-container">
					<Logo />
				</div>
			</div>
			<div className="container container--standard site-info">
				<div className="copyright">
					<span className="copyright__symbol">&#169;</span>{' '}
					<span className="copyright__year">{year}</span>
					<span className="copyright__name">BeachBreakSalon.com</span>
					<span className="copyright__rights">All rights reserved.</span>
				</div>

				<Address footer />
			</div>
		</footer>
	)
}

export default Footer
