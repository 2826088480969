import React from 'react'

const InputButton = ({
	label,
	variant,
	outline,
	size,
	block,
	className,
	type,
	onClick,
	cursor,
	disabled,
	noBdr,
	style,
}) => {
	return (
		<input
			defaultValue={label}
			// prettier-ignore
			className={`btn btn-${variant}${outline ? '-outline ' : ' ' }${size ? 'btn-' + size + ' ': ''}${block ? ' block ': ''}${className ? className + ' ' : ''}${disabled ? ' disabled ' : ''}${noBdr ? ' no-border':''}`}
			type={type}
			style={
				cursor && style
					? { cursor: cursor, ...style }
					: !cursor && style
					? style
					: cursor && !style
					? { cursor: cursor }
					: null
			}
			disabled={disabled ? true : false}
			onClick={onClick}
		/>
	)
}

export default InputButton
