import React from 'react'

const MapIcon = () => {
	return (
		<svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M50 0H0V50H50V0Z" fill="white" />
			<path d="M48.5723 1.42899H1.42899V48.5723H48.5723V1.42899Z" fill="#FBFDFF" />
			<path d="M48.5723 1.42899H1.42899V48.5723H48.5723V1.42899Z" fill="#F3EDD2" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.42899 23.3224V21.3784C4.85443 20.646 5.61493 20.8428 7.67403 17.6717C9.82005 14.36 11.304 13.0614 15.4005 14.732C23.5193 18.0449 28.784 15.0643 36.1065 11.5647C40.3091 9.55163 44.4426 7.41328 48.5698 5.25449V7.389C44.674 9.41742 40.773 11.428 36.8108 13.3222C29.014 17.0518 23.348 20.0248 14.6885 16.4882C11.9239 15.3595 10.6981 16.4882 9.26277 18.6981C6.75376 22.5722 5.60343 22.4571 1.42899 23.3224Z"
				fill="#2FABE1"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.03525 34.8181L25.0518 37.0255L23.4988 48.5697H24.9367L26.4577 37.2198L40.5851 39.1664C40.8408 38.7152 41.1117 38.2717 41.398 37.8397L26.6482 35.8074L28.3673 23.0259L26.9562 22.8661L25.2422 35.6131L9.24231 33.4096L9.03525 34.8181Z"
				fill="#FBFDFF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M26.969 12.4288L48.5697 2.3467V1.42899H21.7848L18.8757 9.92741C17.039 15.2918 24.2248 13.712 26.969 12.4288Z"
				fill="#91C8CE"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M48.5697 48.5698H41.6946C41.3188 44.3953 44.4324 39.7505 48.5697 35.6604V48.5698Z"
				fill="#2FABE1"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M48.5698 32.6887V30.1733C42.6174 35.1683 38.2589 40.5839 37.9125 48.5314L38.8609 48.5723H39.8119C40.1186 41.9234 43.6259 37.0983 48.5698 32.6887Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.00553 48.571L9.33176 32.8063L13.0384 21.3234L48.5723 25.2627V23.3493L13.634 19.4765L19.3652 1.71913L18.4616 1.42899H17.4634L7.49634 32.3091L7.4695 32.3935L7.45927 32.4625L5.08191 48.571H7.00553Z"
				fill="#30ACB9"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M28.3405 34.652L38.1158 35.879C39.4169 34.7862 39.6815 32.4344 38.7318 30.9683L28.9565 29.7413L28.3405 34.652ZM29.1559 28.5577L34.689 29.2531L35.1888 25.278L29.6531 24.5827L29.1546 28.5577H29.1559ZM36.0758 29.4269L36.5756 25.4518L46.2435 26.6661L45.745 30.6411L36.0758 29.4269ZM21.5599 48.5698L21.9561 45.3463L9.50051 43.8125L8.91512 48.5698H21.5599ZM9.78042 42.3068L10.463 36.7622L13.6941 37.1597L13.4385 39.1932L19.5391 39.9435L19.7947 37.91L22.9173 38.2934L22.2398 43.8406L9.78042 42.3068ZM30.7958 44.982L31.4898 39.4476L27.5161 38.9491L26.8207 44.4797L30.7958 44.9794V44.982ZM36.2151 45.662L36.9105 40.1288L32.9354 39.6227L32.2401 45.1571L36.2151 45.6556V45.662ZM35.8547 48.5698H26.3069L26.6904 45.4792L36.0848 46.7075L35.8547 48.5698ZM18.8412 27.6081L24.6977 28.3443L25.2256 24.1366L19.3691 23.4017L18.8412 27.6081ZM18.1907 32.7795L24.0484 33.5157L24.5763 29.3093L18.7198 28.5731L18.1907 32.7795ZM12.4658 32.0612L13.643 22.691L18.1907 23.2624L17.0148 32.6325L12.4658 32.0612ZM48.5698 9.95681L48.378 9.9338L47.6431 15.7903L48.5698 15.9066V9.95681ZM48.5698 17.1017L45.0267 16.6569L44.4554 21.2058L48.5698 21.7286V17.1017ZM46.5362 12.7176L42.5152 12.2064L42.1522 15.0937L46.1732 15.605L46.5362 12.7163V12.7176ZM1.42899 4.66526L1.8904 4.7803L2.17287 3.67086C4.93751 3.14171 8.50994 3.89071 10.3364 5.70696L10.0603 6.81384L13.0372 7.55644L14.5709 1.42899H1.42899V4.66271V4.66526ZM2.15242 15.3135L6.2425 16.3016L7.21901 12.2524L1.42899 10.8515V18.3121L2.15242 15.311V15.3135ZM36.4567 17.2678L33.7432 16.9278L33.3866 19.8114L42.9369 21.0103L43.5083 16.4626L36.6651 15.6037L36.4567 17.2678Z"
				fill="#91C8CE"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.42897 45.9278L5.95235 31.1281C7.21389 26.9984 6.99532 24.2964 3.26823 25.1949L1.42514 25.48L1.42897 45.9278Z"
				fill="#91C8CE"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.42899 8.9854L13.9983 12.1642L14.5837 10.3505L1.42899 7.02344V8.9854Z"
				fill="#FBFDFF"
			/>
			{/* <g clip-path="url(#clip0)"> */}
			<path
				d="M37.069 16.1843C37.0695 14.3066 36.5001 12.4652 35.4237 10.8632C34.3473 9.26118 32.8056 7.96069 30.9685 7.10512C29.1315 6.24954 27.0705 5.87211 25.0127 6.01443C22.9549 6.15674 20.9803 6.81328 19.3065 7.91166C17.6327 9.01005 16.3247 10.5076 15.5268 12.2393C14.7288 13.9709 14.4719 15.8695 14.7844 17.7255C15.0968 19.5816 15.9664 21.3231 17.2974 22.7581C18.6284 24.1931 20.369 25.2658 22.3274 25.8581L25.8492 39.0065L29.386 25.8639C31.6234 25.1915 33.5704 23.8939 34.9499 22.1559C36.3294 20.4179 37.071 18.3281 37.069 16.1843V16.1843ZM25.8599 20.7401C24.872 20.739 23.9065 20.4714 23.0855 19.9713C22.2646 19.4712 21.6249 18.761 21.2473 17.9303C20.8697 17.0996 20.7712 16.1858 20.9641 15.3042C21.157 14.4225 21.6327 13.6127 22.3311 12.9769C23.0296 12.3412 23.9194 11.908 24.8883 11.7321C25.8572 11.5562 26.8616 11.6455 27.7748 11.9887C28.6879 12.3319 29.4688 12.9136 30.0188 13.6603C30.5688 14.4071 30.8632 15.2854 30.8649 16.1843C30.8658 16.7826 30.7369 17.3752 30.4857 17.9282C30.2345 18.4812 29.8659 18.9836 29.401 19.4068C28.9361 19.83 28.3841 20.1655 27.7764 20.3943C27.1688 20.6231 26.5175 20.7406 25.8599 20.7401V20.7401Z"
				fill="#D92F56"
			/>
			{/* </g> */}
			<defs>
				<clipPath id="clip0">
					<rect
						width="22.4138"
						height="33.0071"
						fill="white"
						transform="translate(14.6552 6)"
					/>
				</clipPath>
			</defs>
		</svg>
	)
}

export default MapIcon
