import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import ReactDOM from 'react-dom'
import ModalContent from './ModalContent'

const Modal = ({ children, className, size, hide }) => {
	const elemToAppendTo = document.getElementById('___gatsby')
	// const [isPresent, safeToRemove] = usePresence()

	// useEffect(() => {
	// 	if (!isPresent) {
	// 		setTimeout(safeToRemove, 500)
	// 	}
	// }, [isPresent])

	const modal = (
		<div
			key="modal-container"
			className={`modal${className ? ' ' + className : ''}${size ? ' modal--' + size : ''}`}
		>
			<motion.div
				key="modal-backdrop"
				className="modal__backdrop"
				// onClick={() => hide()}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				onClick={() => hide(false)}
			></motion.div>

			<ModalContent
				key="modal-content-component"
				children={children}
				hide={(thisState) => hide(thisState)}
			/>
		</div>
	)

	return ReactDOM.createPortal(modal, elemToAppendTo)
}

export default Modal
