import React, { useState } from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion, AnimatePresence } from 'framer-motion'

const MapPin = () => {
	const [showPopUp, setShowPopUp] = useState(false)
	const handleMapPinHover = (isHovering) => {
		if (isHovering) {
			setShowPopUp(true)
		} else {
			setShowPopUp(false)
		}
	}
	// const handleMapClick = () => {}

	return (
		<div className="map-pin-container">
			<Link
				to="https://www.google.com/maps/dir/?api=1&destination=Beach+Break+Salon%2C+Gainesville%2C+Florida"
				target="_blank"
			>
				<div
					className="map-pin"
					onMouseEnter={() => handleMapPinHover(true)}
					onMouseLeave={() => handleMapPinHover(false)}
				>
					<FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'map-marker-alt' }} />
				</div>
			</Link>
			<AnimatePresence>
				{showPopUp && (
					<motion.div
						className="map-pop-up"
						initial={{ opacity: 0, y: -30 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: -30 }}
					>
						Click Pin for Directions
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	)
}

export default MapPin
