import React from 'react'
import Icons from './Icons'

const LinkButton = ({
	Link,
	to,
	variant,
	outline,
	size,
	block,
	className,
	icon,
	iconOnly,
	disabled,
	noBdr,
	inline,
	type,
	onClick,
	cursor,
	style,
	icon2,
	iconType,
	iconSize,
	iconType2,
	iconSize2,
	label,
}) => {
	return (
		<Link
			to={to}
			// prettier-ignore
			className={`btn btn-${variant}${outline ? '-outline ' : ' ' }${size ? 'btn-' + size + ' ': ''}${block ? ' block ' : ''}${className ? className + ' ' : ''}${icon ? ' has-icon' : ''}${iconOnly ? ' icon-only' : ''}${disabled ? ' disabled ' : ''}${noBdr ? ' no-border':''} ${inline ? ' inline':''}`}
			type={type}
			disabled={disabled ? true : false}
			onClick={onClick}
			style={
				cursor && style
					? { cursor: cursor, ...style }
					: !cursor && style
					? style
					: cursor && !style
					? { cursor: cursor }
					: null
			}
		>
			<Icons
				icon2={icon2}
				iconType={iconType}
				icon={icon}
				iconSize={iconSize}
				iconType2={iconType2}
				iconSize2={iconSize2}
				iconOnly={iconOnly}
				label={label}
			/>
		</Link>
	)
}

export default LinkButton
