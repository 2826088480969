import React, { useState, useEffect } from 'react'
import MapIcon from '../svg/MapIcon'
import Modal from './Modal'
import Map from './Map'
import { AnimatePresence } from 'framer-motion'

const Address = ({ mapIcon, footer }) => {
	const [mapVisible, setMapVisible] = useState(false)
	const handleAddressClick = (clickedState) => {
		setMapVisible(clickedState)
	}

	return (
		<>
			<div className="container container--standard spacing-bottom address-container">
				<div className="address-wrapper" onClick={() => handleAddressClick(true)}>
					{mapIcon && (
						<div className="map-icon">
							<MapIcon />
						</div>
					)}
					<div className="address">
						<span>603 West University Avenue{footer ? ', ' : ''}</span>
						<span>Gainesville, FL 32601</span>
					</div>
				</div>
			</div>
			<AnimatePresence>
				{mapVisible && (
					<Modal
						key="modal-component"
						hide={(thisState) => handleAddressClick(thisState)}
						className="modal--map"
					>
						<Map />
					</Modal>
				)}
			</AnimatePresence>
		</>
	)
}

export default Address
