import React from 'react'
import HeaderImg from '../img/header-bg@2x.jpg'
import Logo from '../svg/Logo'

const Header = () => {
	return (
		<header className="header-main">
			<img className="header-main__bg-image img-fluid" src={HeaderImg} alt="shoreline" />
			<div className="header-main__logo logo-container">
				<Logo />
			</div>
		</header>
	)
}

export default Header
