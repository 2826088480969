import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Icons = ({ icon2, iconType, icon, iconSize, iconType2, iconSize2, iconOnly, label }) => {
	return icon ? (
		<div className={`btn-icon-layout${icon2 ? ' double-icon' : ''}`}>
			{!icon2 && (
				<>
					<FontAwesomeIcon
						icon={{
							prefix: iconType ? iconType : 'fas',
							iconName: icon,
						}}
						size={iconSize ? iconSize : null}
					/>
				</>
			)}
			{icon2 && (
				<div className="icon-positioner">
					<FontAwesomeIcon
						icon={{
							prefix: iconType ? iconType : 'fas',
							iconName: icon,
						}}
						size={iconSize ? iconSize : null}
					/>
					<FontAwesomeIcon
						icon={{
							prefix: iconType2 ? iconType2 : 'fas',
							iconName: icon2,
						}}
						size={iconSize2 ? iconSize2 : null}
					/>
				</div>
			)}

			{iconOnly ? null : <span className="btn-text">{label}</span>}
		</div>
	) : (
		<span className="btn-text">{label}</span>
	)
}

export default Icons
